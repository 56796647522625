import profileLeclerc from '../assets/profileLeclerc';
import profileHL from '../assets/profileHL';

const {
  VUE_APP_AWS_CLIENT_DOMAIN, VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID, VUE_APP_HABILITATION_URL,
  VUE_APP_EPM_ADMIN_ENDPOINT, VUE_APP_EPM_URL,
  VUE_APP_AWS_CLIENT_DOMAIN_HL, VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID_HL,
  VUE_APP_HABILITATION_URL_HL, VUE_APP_EPM_ADMIN_ENDPOINT_HL, VUE_APP_EPM_URL_HL,
} = process.env;

const context = new Map();
context.set(VUE_APP_AWS_CLIENT_DOMAIN, {
  userPoolWebClientId: VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  hiFront: VUE_APP_HABILITATION_URL,
  epmAdminFront: VUE_APP_EPM_ADMIN_ENDPOINT,
  epmFront: VUE_APP_EPM_URL,
  title: 'Leclerc',
  defaultProfile: profileLeclerc,

});
context.set(VUE_APP_AWS_CLIENT_DOMAIN_HL, {
  userPoolWebClientId: VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID_HL,
  hiFront: VUE_APP_HABILITATION_URL_HL,
  epmAdminFront: VUE_APP_EPM_ADMIN_ENDPOINT_HL,
  epmFront: VUE_APP_EPM_URL_HL,
  title: 'HorsLeclerc',
  defaultProfile: profileHL,
});

export default {
  get(type) {
    const urls = context.get(window.location.origin);
    if (urls) {
      return urls[type];
    }
    // using Cypress, we set a cookie cy-client
    if (localStorage.getItem('cy-client') === 'HL' || false) {
      return context.get(VUE_APP_AWS_CLIENT_DOMAIN_HL)[type];
    }
    return context.get(VUE_APP_AWS_CLIENT_DOMAIN)[type];
  },
};
