export const FIELDS_PAGE_EMAIL = ['message', 'legal', 'description', 'request_mail_link_message', 'confirm_mail_message', 'success_page_title',
  'success_page_message', 'failure_page_title', 'failure_page_message', 'failure_page_paybutton_message',
  'payment_page_message', 'error_page_message', 'expired_page_message', 'locked_page_message',
  'updated_order_message'];

export const FIELDS_PROFIL_SETTING = ['sender', 'url_legal', 'expirations_presets'];
export const FIELDS_PAYLINE = ['access_key', 'merchant_id', 'ref_template'];

function getEmptyFields(item) {
  const result = {
    pageAndEmail: [],
    profil: [],
    payline: [],
    shop: [],
  };
  if (item.profile) {
    // only shop has a profile
    if (!item.name) {
      result.shop.push('name');
    }
    if (!item.email) {
      result.shop.push('email');
    }
    if (!item.settings.sender_name) {
      result.shop.push('sender_name');
    }
    if (!item.payline.contract_numbers || item.payline.contract_numbers.length === 0) {
      result.shop.push('contract_numbers');
    }
  } else {
    // profile
    FIELDS_PAGE_EMAIL.forEach((field) => {
      if (!item.settings[field]) {
        result.pageAndEmail.push(field);
      }
    });
    FIELDS_PROFIL_SETTING.forEach((field) => {
      if (!item.settings[field]) {
        result.profil.push(field);
      }
    });
    FIELDS_PAYLINE.forEach((field) => {
      if (!item.payline[field]) {
        result.payline.push(field);
      }
    });
  }
  Object.keys(result).forEach((f) => {
    if (result[f].length === 0) {
      delete result[f];
    }
  });

  return result;
}

function canBeEnabled(item) {
  const fields = getEmptyFields(item);
  const missing = fields.profil || fields.shop || fields.payline;
  return missing === undefined;
}

function countEmptyFields(item) {
  const fields = getEmptyFields(item);
  let nb = 0;
  Object.keys(fields).forEach((f) => { nb += fields[f].length; });
  return nb;
}

function getEmptyFieldsValue(item) {
  return JSON.stringify(getEmptyFields(item)).replace(/,/g, ', ');
}

export default {
  getEmptyFields, countEmptyFields, getEmptyFieldsValue, canBeEnabled,
};
