import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  return locales.keys().reduce((acc, key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];

      acc[locale] = locales(key);
    }
    return acc;
  }, {});
}

function loadVeeValidateMessage(resources) {
  // On EdelPay Mail, we do not have vee-validate
  return resources;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: loadVeeValidateMessage(loadLocaleMessages()),
});
