const state = {
};

const mutations = {

};

const actions = {
  add: ({
    dispatch,
  }, notification) => {
    const notificationSnackbar = {
      type: notification.type,
      message: notification.title + (notification.message ? `: ${notification.message}` : ''),
    };
    dispatch('notification/add', notificationSnackbar, { root: true });
  },

};

const getters = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
