import numeral from '../lib/numeral';

// switch between locales
numeral.locale('fr');

function currency(value) {
  if (value) {
    return numeral(value).format('0,0.00$');
  }
  return '';
}
function currencyFormat(value) {
  if (value) {
    return numeral(value).format('0,0.00');
  }
  return '';
}

export { currency, currencyFormat };
