<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-2"
        fab
        :dark='!disabled'
        :disabled='disabled'
        :data-test="'bt-' + type"
        :color="color"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...inputListeners, ...on }"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  data() {
    let properties;
    switch (this.type) {
      case 'save':
        properties = { color: 'green', icon: 'mdi-content-save' };
        break;
      case 'cancel':
        properties = { color: undefined, icon: 'mdi-cancel' };
        break;
      case 'return':
        properties = { color: 'grey', icon: 'mdi-arrow-left' };
        break;
      case 'export':
        properties = { color: 'blue', icon: 'mdi-database-export-outline' };
        break;
      case 'delete':
        properties = { color: 'red', icon: 'mdi-delete' };
        break;

      default:
        // eslint-disable-next-line no-new-wrappers
        throw new String(`invalid type ${this.type}`);
    }
    if (!properties.tooltip) {
      properties.tooltip = this.type;
    }
    return properties;
  },

  computed: {
    inputListeners() {
      // https://fr.vuejs.org/v2/guide/components-custom-events.html#Relier-des-evenements-natifs-aux-composants
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>

<style>
</style>
