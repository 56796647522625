import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/src/locale/fr.ts';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0053A1',
        secondary: '#F28C00',
        accent: '#7AB2E1',
      },
    },
  },
  lang: {
    locales: {
      fr,
    },
    current: 'fr',
  },

});
