import { Auth } from '@aws-amplify/auth';

import api from '../lib/api';

function timeout(ms) {
  // https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}
async function sleep(fn, ms) {
  await timeout(ms);
  return fn();
}

let tokenRefreshTimer = null;
//  after 10hour, the user has to reconnect
const SESSION_DURATION_INACTIVE_MS = 10 * 60 * 60 * 1000;
function willRefreshToken(dispatch) {
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
  }
  // refresh cognito token every 5min
  tokenRefreshTimer = setTimeout(async () => {
    const lastRequestDate = localStorage.getItem('session_lastRequest_date');
    if (lastRequestDate
      && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS) {
      // after 1hour, the user has to reconnect
      dispatch(
        'notifications/add',
        {
          type: 'warn',
          // title: 'Demande renvoyée',
          message: 'Votre session a expirée',
        },
        { root: true },
      );
      await Auth.signOut();
      // the Hub will redirect the user to /login if necessary (see router/index.js)
    } else {
      if (lastRequestDate
      && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS - 2 * 60 * 1000) {
        dispatch(
          'notifications/add',
          {
            type: 'warn',
            // title: 'Demande renvoyée',
            message: 'Votre session va expirer dans moins de 2 minutes',
          },
          { root: true },
        );
      }
      // console.log('refreshing token');
      try {
        const currentSession = await Auth.currentSession();
        const { jwtToken } = currentSession.accessToken;
        api.authenticate(jwtToken);
        willRefreshToken(dispatch);
      } catch (e) {
      // Network error
        console.warn(e);
        if (e.code === 'NotAuthorizedException') {
          //  message: "Refresh Token has expired"
          dispatch(
            'notifications/add',
            {
              type: 'warn',
              // title: 'Demande renvoyée',
              message: 'Votre session a expirée',
            },
            { root: true },
          );

          await Auth.signOut();
        } else {
          willRefreshToken(dispatch);
        }
      }
    }
  }, 5 * 60 * 1000);
}

const store = {
  namespaced: true,

  state: {
    cognito: null,
    loading: null,
    habilitation: null,
    isBackOffice: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.cognito = user;

      if (!user) {
        clearTimeout(tokenRefreshTimer);
        api.authenticate(null);
        state.habilitation = null;
        state.isBackOffice = false;
      }
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_HABILITATION(state, data) {
      state.habilitation = data;
    },
    SET_BACK_OFFICE(state, v) {
      state.isBackOffice = v;
    },
  },
  actions: {
    hasPermissionIn({ state }, perms) {
      // perms can be ['identities.']
      if (state.habilitation) {
        return Object.values(state.habilitation.pByBundleId).some(
          (permissions) => permissions.some((p) => perms.some((perm) => p.key.startsWith(perm))),
        );
      }
      return false;
    },
    async signOut() {
      try {
        // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-out
        await Auth.signOut();
        // the state will be refreshed by the event onAuthUIStateChange
      } catch (error) {
        // console.log('error signing out: ', error);
      }
    },
    async loadUserAndCredentials({ commit, dispatch }, { router }) {
      try {
        await Auth.currentAuthenticatedUser()
          .then((user) => commit('SET_USER', user));
        await dispatch('loadCredentials');
        willRefreshToken(dispatch);
        const route = router.currentRoute;
        // add &&!hasUser to solve 'Avoided redundant navigation to current location'
        if (route.query && route.query.redirect) {
          router.push(route.query.redirect);
        } else if (route.name === 'Login') {
          router.push({ name: 'Home' });
        }
      } catch (e) {
        console.warn('loadUserAndCredentials', e);
      }
    },
    async loadCredentialsHabilitation({
      commit, dispatch,
    }) {
      const { status, data } = await api.name('user').get('/api/auth/v2');
      // console.log(data);
      if (status === 200) {
        commit('SET_HABILITATION', data);
        const isBackOffice = await dispatch('hasPermissionIn', ['EPM.admin.profile.write']);
        commit('SET_BACK_OFFICE', isBackOffice);
      }
    },
    async loadCredentials({
      commit, state, dispatch,
    }, params = {}) {
      if (!state.loading) {
        commit('SET_LOADING', true);
        try {
          // Récup user
          let myToken = params.jwtToken;
          if (!myToken) {
            const session = await Auth.currentSession();
            if (session) {
              myToken = session.accessToken.jwtToken;
            }
          }
          if (myToken) {
            // commit('SET_USER', user);
            api.authenticate(myToken);
            await dispatch('loadCredentialsHabilitation');
            commit('SET_LOADING', false);
          } else {
            // no session
            await sleep(() => {
              commit('SET_LOADING', false);
            }, 500);
          }
        } catch (e) {
          // no session
          await sleep(() => {
            commit('SET_LOADING', false);
          }, 500);
        }
      }
    },
  },
};
export default {
  ...store,
};
