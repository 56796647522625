// File inspired from : https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource

import multiClient from './lib/multiClient';

const awsConf = {
  // https://docs.amplify.aws/lib/auth/start/q/platform/js#configure-your-application
  Auth: {
  // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: multiClient.get('userPoolWebClientId'),

    // authenticationFlowType is 'USER_SRP_AUTH' by default
    // oauth: {
    //   scope: ['email', 'profile', 'openid'],
    //   redirectSignIn: process.env.VUE_APP_AWS_REDIRECT_SIGNIN,
    //   redirectSignOut: process.env.VUE_APP_AWS_REDIRECT_SIGNOUT,
    //   responseType: 'code',
    // },
    clientMetadata: {
      application_id: '11000',
    },
  },
};
export default awsConf;
