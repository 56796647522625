const moment = require('moment');

function processStats(statsResults) {
  const array2Object = (array) => array.map((valuesByDay) => {
    const i = {};
    valuesByDay.forEach((element) => {
      let value;
      if (element.field === 'day') {
        value = moment(element.value);
      } else if (element.field === 'count' || element.field === 'sum' || element.field === 'ca') {
        value = +(element.value);
      } else {
        value = element.value;
      }
      i[element.field] = value;
    });
    return i;
  });

  const currentMonth = moment().format('M');
  const now = moment();

  const caByDay = statsResults.insight.filter((q) => q.name === 'caByDay')[0];
  const m = array2Object(caByDay.results);

  const caByShop = m.reduce((acc, valuesByDay) => {
    let s = acc[valuesByDay.shop];
    if (!s) {
      const { shop, profileId } = valuesByDay;
      s = {
        shop,
        profileId,
        CA: 0,
        CA_LastMonth: 0,
        active: false,
      };
      acc[valuesByDay.shop] = s;
    }
    //         ca: "912"
    // day: Moment
    // profileId: "Generique_eleclerc"
    // shop: "LG458"
    if (currentMonth === valuesByDay.day.format('M')) {
      s.CA += valuesByDay.ca;
    } else {
      s.CA_LastMonth += valuesByDay.ca;
    }
    return acc;
  }, {});

  const emailByDay = statsResults.insight.filter((q) => q.name === 'nbEmailByDay')[0];

  const emailByShop = array2Object(emailByDay.results).reduce((acc, valuesByDay) => {
    let s = acc[valuesByDay.shop];
    if (!s) {
      const { shop, profileId } = valuesByDay;
      s = {
        shop,
        profileId,
        nbEmail: 0,
        nbEmailLastMonth: 0,
        active: false,
      };
      acc[valuesByDay.shop] = s;
    }
    // count: 912
    // day: Moment
    // profileId: "Generique_eleclerc"
    // shop: "LG458"
    if (currentMonth === valuesByDay.day.format('M')) {
      s.nbEmail += valuesByDay.count;
    } else {
      s.nbEmailLastMonth += valuesByDay.count;
    }
    if (!s.active) {
      s.active = now.diff(valuesByDay.day, 'days') < 7;
    }

    return acc;
  }, {});

  // merge statistics
  const statsShops = Object.keys(emailByShop).map((shop) => {
    const ca = caByShop[shop] || {
      CA: 0,
      CA_LastMonth: 0,
    };
    return { ...ca, ...emailByShop[shop] };
  });
  // console.log('statsShops', statsShops);
  const statsByProfile = statsShops.reduce((acc, s) => {
    let profile = acc[s.profileId];
    if (!profile) {
      profile = {
        id: s.profileId,
        ptv: 0,
        ptvTotal: 0,
        shops: [],
        nbEmail: 0,
        nbEmailLastMonth: 0,
        CA_LastMonth: 0,
        CA: 0,
      };
      acc[s.profileId] = profile;
    }
    profile.shops.push(s);
    profile.nbEmail += s.nbEmail;
    profile.nbEmailLastMonth += s.nbEmailLastMonth;
    profile.CA += s.CA;
    profile.CA_LastMonth += s.CA_LastMonth;
    return acc;
  }, {});
  const { merchantsByProfile } = statsResults.db;
  const statsProfile = Object.keys(merchantsByProfile).map((id) => {
    const stat = statsByProfile[id];
    if (stat) {
      // sort desc by CA
      stat.shops.sort((a, b) => b.CA - a.CA);
      stat.ptv = stat.shops.length;
      stat.ptvTotal = (merchantsByProfile[id]).length;
      stat.ptvTop = stat.shops.slice(0, Math.min(3, stat.shops.length)).map((s) => s.shop);
      if (stat.shops.length > 3) {
        stat.ptvTopRemaining = stat.shops
          .slice(3, Math.min(10, stat.shops.length))
          .map((s) => s.shop);
      }
      return stat;
    }
    return {
      id,
      ptv: 0,
      ptvTotal: (merchantsByProfile[id]).length,
      ptvTop: [],
      shops: [],
      nbEmail: 0,
      nbEmailLastMonth: 0,
      CA_LastMonth: 0,
      CA: 0,
    };
  });
  // console.log('statsProfile', statsProfile);
  return { statsProfile, statsShops };
}

module.exports = {
  processStats,
};
