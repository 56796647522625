import Vue from 'vue';
import Vuex from 'vuex';
import notification from '@edel/edel-ui/src/store/modules/notification';

import profile from './profile';
import user from './user';
import notifications from './notifications';
import file from './file';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    loading: {},
  },
  mutations: {
    setLoading(state, p) {
      state.loading = { ...state.loading, ...p };
    },
  },
  modules: {
    file,
    profile,
    user,
    notifications,
    notification,
  },

});
