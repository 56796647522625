<template>
  <div class="fileImport">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-badge color="blue" overlap>
          <template v-slot:badge>
            <v-icon color="white" @click="$emit('help')"
             :data-test="dataTest+'-help'" > mdi-help </v-icon>
          </template>
          <v-btn
            class="mx-2"
            fab
            dark
            color="indigo"
             :data-test="dataTest"
            v-bind="attrs"
            v-on="on"
            @click="onButtonClick"
            :loading="loading"
          >
            <v-icon dark> {{ icon }} </v-icon>
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            :accept="accept"
            @change="onFileChanged"
            :data-test="'i-'+dataTest"
          />
        </v-badge>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: String,
    accept: String,
    icon: String,
    loading: Boolean,
    value: Object,
    dataTest: {
      default: 'bt-file-import',
      Type: String,
    },

  },

  data: () => ({
    selectedFile: null,
    isSelecting: false,
  }),
  computed: {
    buttonText() {
      if (this.value) {
        return this.selectedFile
          ? this.selectedFile.name
          : this.defaultButtonText;
      }
      return this.defaultButtonText;
    },
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = e.target.files[0];
      this.$emit('change', e.target.files);
      // https://stackoverflow.com/questions/59658463/how-to-reset-clear-file-input
      // reset the input file
      this.$refs.uploader.value = null;
    },
  },
};
</script>

<style>
</style>
