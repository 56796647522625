import Vue from 'vue';

import ComponentLibrary from '@edel/edel-ui';

import Amplify from '@aws-amplify/core';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

import awsExports from './aws-exports';
import './locale/AmplifyI18n';
import './styles/main.scss';
import api from './lib/api';
import btn from './components/lib/Btn.vue';
import './filters';

Vue.component('btn', btn);
Vue.use(ComponentLibrary);

Vue.config.productionTip = false;

Amplify.configure(awsExports);
api.connectStore(store);
// console.log('current language:', window.navigator.language);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
