<template>
  <v-app >
    <e-notif />
    <v-app-bar app  class="navbar navbar-light"  >
      <div class="d-flex align-center">
        <nav   >
          <router-link class="nav-link" to="/">
            <span class="navbar-brand mb-0 h1">
              <div class="logo"></div>
              {{ title }}
            </span>
          </router-link>
        </nav>
      </div>

      <v-spacer></v-spacer>
      <div class="mt-5">
        <AppBar v-if="habilitation"/>
      </div>
      <div class="ml-5">
        <UserMenu v-if="habilitation" />
      </div>

    </v-app-bar>

    <v-main>
      <!-- <ConfMerchant/>
    <ListMerchant/>
    <ListMerchantTable/>
      <ConfMerchantProfile/> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// import ConfMerchant from '@/components/ConfMerchantList.vue';
import AppBar from '@/components/AppBarCombobox.vue';
import UserMenu from '@/components/user/UserMenu.vue';

export default {
  name: 'App',

  components: {
    AppBar,
    UserMenu,

  },
  computed: {
    ...mapState('user', ['habilitation']),
    title() {
      return process.env.VUE_APP_NAME;
    },
  },

  methods: {
    ...mapActions('user', [
      'loadUserAndCredentials',
    ]),
  },
  created() {
    setTimeout(() => {
      // use setTimeout otherwise query is empty and path /
      const { query } = this.$route;
      if (query && query.a) {
        // console.log('created', 'user from Alertel: do not load credentials');
      } else {
        this.loadUserAndCredentials({ router: this.$router, onSignIn: false });
      }
    }, 1000);
  },

};
</script>

<style lang="scss">

@import "./styles/settings";
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.navbar {
  border-top: solid 3px $primary;
   border-color: $primary  ;

  background-color: white;
  box-shadow: 0 0 10px rgba($black, .05);
  margin-bottom: 1em;

  .spinner-border {
    font-size: .5em;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.navbar-light .navbar-brand {
  color: $primary !important;
  border-color: $primary  ;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  span {
    color: $orange;
  }

}

.nav-item {
    a.nav-link {
      font-weight: bold;
      text-transform: uppercase;
      font-size: .7em;
      color: $secondary;

      &.router-link-exact-active {
        color: $primary;
      }
  }
}

</style>
