import api from '../lib/api';
import { processStats } from '../lib/stats';
import { downloadFile } from '../lib/download';
import shopModule from './shop';

function processLine(l, mdpMap) {
  const errors = [];
  const cols = l.split(/[,;]/);
  const [code, mdp, contractNumber, name, email, senderName] = cols
    .map((v) => v.replace(/(["']?)(.*)(\\1)/, '$2').trim());
  if (!/^\w+$/.test(code)) {
    errors.push(`incorrect code: ${code}. Expected: [a-zA-Z0-9_]+ `);
  }
  if (!contractNumber) {
    errors.push(`incorrect contractNumber ${contractNumber} pour : ${code}. `);
  }
  if (!mdp) {
    errors.push(`Le Moyen de paiment est vide pour : ${code}`);
  }
  const mdpId = mdpMap[mdp];
  if (!mdpId) {
    const mdpNames = Object.keys(mdpMap).join(', ');
    errors.push(`Moyen de paiment ${mdp} non disponible pour le point de vente ${code}, veuillez utiliser les moyens de paiements suivants : ${mdpNames}`);
  }
  if (!name) {
    errors.push(`no name for : ${code}`);
  }
  if (!email) {
    errors.push(`no email for : ${code}`);
  }
  if (!senderName) {
    errors.push(`no senderName for : ${code}`);
  }
  if (errors.length > 0) {
    return { errors };
  }
  return {
    line: {
      code, contractNumber, name, email, senderName, mdpId,
    },
  };
}

const store = {
  namespaced: true,

  state: {
    profiles: [],
    currentProfile: null,
    shops: [],
    statisticsProfile: [],
    statisticsShop: [],
    stats: {
      ids: null,
      results: null,
    },
    conf: { },
    deleteMpdProfileAndShop: [],

  },
  mutations: {
    setCurrentProfile(state, p) {
      if (p) {
        // create a copy to cancel
        state.currentProfile = { ...p };
        state.currentProfile.settings = { ...p.settings };
        state.currentProfile.payline = { ...p.payline };
        if (!state.currentProfile.payline.paymentOptions) {
          state.currentProfile.payline.paymentOptions = [];
        } else {
          state.currentProfile.payline.paymentOptions = [...p.payline.paymentOptions];
        }
      } else {
        state.currentProfile = undefined;
        state.shops = [];
      }
      // console.log(`setCurrentProfile   ${JSON.stringify(p.payline)}`);
    },
    setProfiles(state, p) {
      state.profiles = p;
    },
    setShops(state, p) {
      if (p) {
        // sort shops by name
        p.sort((a, b) => a.name.localeCompare(b.name));
      }
      state.shops = p;
    },
    setStatistics(state, p) {
      const { statsProfile, statsShops } = p;
      state.statisticsProfile = statsProfile;
      state.statisticsShop = statsShops;
    },
    setStatsIds(state, p) {
      state.stats.ids = p;
    },
    setStatsResults(state, p) {
      if (state.stats.results) {
        state.stats.results.insight = [...state.stats.results.insight, ...p.insight];
      } else {
        state.stats.results = p;
      }
    },
    updateSettings(state, p) {
      const newProp = { ...state.currentProfile };
      newProp.settings = { ...newProp.settings, ...p };
      state.currentProfile = newProp;
    },
    setConf(state, p) {
      state.conf = p;
    },
    mpdProfileAndShopToDeleteAdd(state, v) {
      state.deleteMpdProfileAndShop.push(v);
    },

  },
  actions: {
    async get({ state }, profileId) {
      const profileIdCleaned = profileId.trim().toUpperCase();
      return state.profiles.find((p) => p.id.toUpperCase() === profileIdCleaned);
    },

    mpdProfileAndShopToDeleteAdd({ commit }, profileAndShop) {
      commit('mpdProfileAndShopToDeleteAdd', profileAndShop);
    },
    async  mpdProfileAndShopDelete({ state }) {
      if (state.deleteMpdProfileAndShop.length > 0) {
        await api.name('conf_save').post('/admin/conf/Mdp/delete', state.deleteMpdProfileAndShop);
        state.deleteMpdProfileAndShop = [];
      }
    },

    async loadFirstShop({ state, dispatch }, router) {
      await dispatch('loadList');
      if (state.profiles.length > 0) {
        const p = state.profiles[0];
        await dispatch('load', p.id);
        if (state.shops.length > 0) {
          const shopId = state.shops[0].id;
          router.push({
            name: 'shop-edit-page-email',
            params: { id: p.id, shopId },
          });
        }
      }
    },
    async load({ commit, state, dispatch }, profileId) {
      // console.log(`OK profileId ${JSON.stringify(profileId)}`);
      if (profileId) {
        if (state.profiles.length === 0) {
          await dispatch('loadList');
        }
        if (!state.currentProfile || state.currentProfile.id !== profileId) {
          commit('setCurrentProfile', state.profiles.filter((p) => p.id === profileId)[0]);
          const response = await api.name('profile_get_shops').get(`/admin/profile/${profileId}/shops`);
          if (response.status === 200) {
            commit('setShops', response.data);
          }
        }
      } else {
        commit('setCurrentProfile', undefined);
      }
    },
    async loadList({
      commit, dispatch, state, rootState,
    }) {
      if (!state.stats.ids && rootState.user.isBackOffice) {
        dispatch('loadStatistics');
        dispatch('loadConfPayment');
      }
      const tr = await api.name('profile_get').get('/admin/profile');
      if (tr.status === 200) {
        commit('setProfiles', tr.data);
      }
    },
    async loadConfPayment({ commit }) {
      const params = { paymentMethods: true };
      const result = await api.name('conf_get')
        .get('/admin/conf', { params });
      commit('setConf', result.data);
    },
    async saveConf({ state, dispatch, commit }, router) {
      await api.name('conf_save').post('/admin/conf', state.conf);
      if (state.deleteMpdProfileAndShop.length > 0) {
        await dispatch('mpdProfileAndShopDelete');
        // refresh profiles because some mdp options can have been deleted
        await dispatch('loadList');
      }
      if (state.currentProfile) {
        const { id } = state.currentProfile;
        commit('setCurrentProfile', undefined);
        router.push({
          name: 'merchant-payline',
          params: { id },
        });
      }
    },
    async loadStatisticsResult({ commit, dispatch, state }) {
      let params = {};
      let nbQuery;
      if (!state.stats.results) {
        params = {
          db: JSON.stringify(['shopByProfile']),
          insight: JSON.stringify(state.stats.ids),
        };
        nbQuery = state.stats.ids.length;
      } else {
        const missingQueries = state.stats.ids.filter(
          (q) => !state.stats.results.insight.some((result) => result.name === q.name),
        );
        params = {
          insight: JSON.stringify(missingQueries),
        };
        nbQuery = missingQueries.length;
      }
      const result = await api.name('profile_get_statistics')
        .get('/admin/stats/result', { params });
      // console.log('stats', result);
      commit('setStatsResults', result.data);

      if (result.data.insight.length < nbQuery) {
        // console.log('loadStatisticsResult incomplete', params.insight, result.data.insight);
        setTimeout(() => dispatch('loadStatisticsResult'), 30000);
      } else {
        dispatch('processStatisticsResult');
      }
    },

    async processStatisticsResult({ commit, state }) {
      const result = processStats(state.stats.results);

      commit('setStatistics', result);
    },
    async loadStatistics({ commit, dispatch }) {
      const tr = await api.name('profile_get_statistics').get('/admin/stats/ids');
      if (tr.status === 200) {
        commit('setStatsIds', tr.data);
        if (tr.data.some((q) => q.status !== 'Complete')) {
          // console.log('wait before loading stats result');
          setTimeout(() => { dispatch('loadStatisticsResult'); }, 30000);
        } else {
          dispatch('loadStatisticsResult');
        }
      }
    },

    async save({ state, commit, dispatch }) {
      const logoURL = await dispatch('file/saveLogo', undefined, { root: true });
      if (logoURL) {
        state.currentProfile.settings.logo = logoURL;
      }
      const { status, data } = await api.name('profile_save').post('/admin/profile', state.currentProfile);
      // console.log('saveProfile', status);
      if (status === 200) {
        const list = state.profiles.filter((s) => s.id !== data.id);
        list.push(data);
        commit('setProfiles', list);
        commit('setCurrentProfile', data);
        if (state.deleteMpdProfileAndShop.length > 0) {
          await dispatch('mpdProfileAndShopDelete');
          // rafraichir les points de vente
          // lorsque l'on supprime un Moyen de Paiement sur un profil
          const { id } = state.currentProfile;
          commit('setCurrentProfile', undefined);
          await dispatch('load', id);
        }
      }
    },

    async import({ dispatch, state }, profileString) {
      const profile = JSON.parse(profileString);
      if (state.profiles.some((p) => p.id === profile.id)) {
        const message = `Un profil avec l'identifiant '${profile.id}' existe déjà.`;
        dispatch(
          'notifications/add',
          {
            title: 'Erreur',
            type: 'error',
            message,
          },
          { root: true },
        );
      } else {
        const profileCleaned = profile;
        profileCleaned.payline = {};
        profileCleaned.enabled = false;
        profileCleaned.settings.logo = undefined;
        await api.name('profile_import').post('/admin/profile', profileCleaned);
        dispatch(
          'notifications/add',
          {
            title: 'Profil importé',
            type: 'success',
            message: 'Attention: la conf Payline & le logo ne sont pas importés',
          },
          { root: true },
        );
        dispatch('loadList');
      }
    },
    cancel({ state, commit }) {
      // console.log('cancel');
      const profileId = state.currentProfile.id;
      commit('setCurrentProfile', state.profiles.filter((p) => p.id === profileId)[0]);
      state.deleteMpdProfileAndShop = [];
    },
    async remove({ state, dispatch }) {
      const { status } = await api.name('profile_remove').delete(`/admin/profile/${state.currentProfile.id}`);
      if (status === 200) {
        dispatch('loadList');
      }
    },
    async importProfile({ dispatch }, files) {
      const reader = new FileReader();

      // Use the javascript reader object to load the contents
      // of the file in the v-model prop
      reader.readAsText(files[0]);
      reader.onload = () => {
        dispatch('import', reader.result);
      };
    },
    async importShops({ dispatch, state }, files) {
      const reader = new FileReader();

      const paymentOptionsProfile = state.currentProfile.payline.paymentOptions;
      const mdps = state.conf.payment.paymentOptions;
      const mdpsMap = mdps.reduce((acc, e) => {
        if (paymentOptionsProfile.some((p) => p.mdpId === e.mdpId)) {
          acc[e.name] = e.mdpId;
        }
        return acc;
      }, {});

      // Use the javascript reader object to load the contents
      // of the file in the v-model prop
      reader.readAsText(files[0]);
      reader.onload = async () => {
        const allTextLines = reader.result.split(/\r\n|\n/);
        const errors = [];
        const result = [];
        const cols = allTextLines[0].split(/[,;]/).filter((e) => e && e.trim());
        if (cols.length === 6) {
          for (let i = 1; i < allTextLines.length; i += 1) {
            if (allTextLines[i].length > 0) {
              const output = processLine(allTextLines[i], mdpsMap);
              if (output.errors) {
                errors.push(output.errors);
              } else {
                result.push(output.line);
              }
            }
          }
        } else {
          // Code;Moyen de paiement;Contrat Number;Nom de l'agence;Email de l'agence;Nom de
          //  l'expéditeur du mail;
          errors.push('Le header est incorrect.');
        }
        if (errors.length > 0) {
          // console.log(errors);
          dispatch(
            'notifications/add',
            {
              title: "Fichier rejeté. Aucune donnée intégrée. Veuillez consulter le fichier d'erreur pour plus de détails.",
              type: 'error',
              message: '',
            },
            { root: true },
          );
          downloadFile('error_import_front.txt', errors.join('\r\n'), 'text/plain');
        } else {
          const { id } = state.currentProfile;
          try {
            await api.name('profile_import_shops')
              .post(`/admin/profile/${id}/shops`, result);
            dispatch(
              'notifications/add',
              {
                title: 'Point(s) de vente importé(s)',
                type: 'success',
                message: '',
              },
              { root: true },
            );
            dispatch('load', null);

            dispatch('load', id);
          } catch (e) {
            downloadFile('error_import_server.txt', e.join('\r\n'), 'text/plain');
          }
        }
      };
    },
    goBack(_, router) {
      router.push({ name: 'Home' });
    },

  },
  modules: {
    shop: shopModule,
  },

};

export default {
  ...store,
};
