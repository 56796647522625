export default {
  success_page_title: 'Confirmation de paiement',
  success_page_message: 'Votre boutique vous remercie pour votre paiement.',
  failure_page_title: 'Echec de paiement',
  capture: true,
  locked_page_message: 'Nous sommes désolés mais le processus de paiement est déjà en cours. Ce lien n’est plus valable.<br/><br/>Votre boutique',
  request_mail_link_message: "Si le bouton ci-dessus de fonctionne pas, nous vous invitons à copier/coller le lien suivant dans la barre d'adresse de votre navigateur.",
  message: "Bonjour,<br/><br/>Nous vous prions de trouver ci-joint le lien vous permettant d'effectuer le paiement en ligne de votre commande. Votre paiement sera sécurisé par un code confidentiel que vous demandera votre banque (3DS) ou par l'usage d'une e-Carte Bleue.<br/><br/> Nous restons à votre disposition par téléphone pour toute information complémentaire.<br/><br/> Votre boutique.   ",
  primary_color: '#0053A1',
  payment_page_message: "Bienvenue sur l'interface de paiement à distance de votre boutique.<br/><br/>Nous vous invitons à procéder au règlement en cliquant sur le bouton ci-dessous. Le système de paiement est totalement sécurisé. Un code confidentiel pourra vous être demandé par votre banque pour valider votre paiement.",
  updated_order_message: "Nous sommes désolés mais ce lien n'est plus valable. <br/><br/>Votre boutique",
  confirm_mail_message: 'Votre boutique vous remercie pour votre paiement.<br/><br/> Vous pouvez désormais venir retirer votre commande, muni de cet email au service "Retrait Commandes" de votre magasin.<br/><br/> A très bientôt dans votre boutique',
  expired_page_message: 'Nous sommes désolés mais le délai de paiement accordé à cette offre est expiré. Nous vous invitons à contacter votre boutique pour lui demander un renouvellement de demande de paiement sur internet.<br/><br/>Votre boutique',
  storage_duration_days: 90,
  legal: "La Banque Edel traite les données recueillies pour la bonne exécution de l'opération de paiement. Elles sont conservées jusqu'au complet paiement et jusqu'à 13 mois suivant la date de débit à des fins de preuve en cas de contestation de la transaction.<br/>Pour en savoir plus et pour exercer vos droits, reportez-vous à la notice Protection des données, disponible sur le site internet de la Banque Edel.",
  error_page_message: 'Nous sommes désolés mais une erreur est survenue, merci de vous rapprocher de votre boutique.',
  failure_page_message: "Votre paiement n'a pas pu être pris en compte. Nous vous invitons à effectuer les vérifications suivantes :<br/><ul class=\"list\">        <li>Votre plafond de paiement restant est-il suffisant pour couvrir la somme à régler ? Si non, contactez votre banque.</li>        <li>Avez-vous saisi le bon code d'identification ? Le paiement sécurisé nécessite la saisie complémentaire d'un code fourni par votre banque. Il peut s'agir par exemple d'un code envoyé par SMS.</li>        <li>Votre carte peut-elle effectuer un paiement sur internet ? Certaines cartes ne permettent pas de payer sur internet. Renseignez-vous auprès de votre banque.</li>      </ul>",
  expirations_presets: [
    {
      value: '1h',
      label: '1 heure',
    },
    {
      value: '2h',
      label: '2 heures',
    },
    {
      value: '3h',
      label: '3 heures',
    },
  ],
};
