<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary"   v-bind="attrs" v-on="on" :loading="loading">
          {{ user ? user.username : "non connecté" }}
          <v-icon class="ml-2">mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list link dense>
        <v-list-item @click="signOut" v-if="user">
          <v-list-item-title> se déconnecter </v-list-item-title>
        </v-list-item>
        <v-list-item v-else to="/login">
          <v-list-item-title> se connecter </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="user && user.authenticationFlowType"
          to="/pwd"
          data-test="bt-nav-change-pwd"
        >
          <!-- user coming from Alertel do not have user.signInUserSession -->
          <v-icon small class="mr-2">mdi-lock-outline</v-icon>
          <v-list-item-title class="body-2">
            Changer son mot de passe
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isBackOffice && helpUrl" :href="helpUrl" target="_blank" tag="li">
          <v-icon class="mr-2">mdi-help-circle</v-icon>
          <v-list-item-title> aide </v-list-item-title>
          <v-icon small class="ml-2">mdi-open-in-new</v-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: (state) => state.user.cognito,
      isBackOffice: (state) => state.user.isBackOffice,
      loading: (state) => state.user.loading,
    }),
    helpUrl() {
      return process.env.VUE_APP_HELP_URL;
    },
  },

  methods: {
    ...mapActions('user', ['signOut']),
  },
};
</script>
