<template>
  <div class="pb-0">
    <v-row no-gutters class="pa-2">
      <v-col class="pb-0">
        <v-combobox
          class="pr-5"
          v-model="currentProfileSelected"
          :items="profiles"
          label="Profil"
          hide-selected
          data-test="profiles"
          :item-text="getText"
        >
          <template v-slot:selection="{  item }">
            {{ item.name }}
          </template>

          <template v-slot:item="{  item }">
            {{ item.name }}
          </template>
        </v-combobox> </v-col
      ><v-col>
        <v-combobox
          :disabled="shops.length === 0"
          v-model="currentShopSelected"
          :items="shops"
          label="Point de vente"
          hide-selected
          data-test="shops"
          :item-text="getText"
        >
          <template v-slot:selection="{  item }">
            {{ item.name }}
          </template>

          <template v-slot:item="{  item }">
            {{ item.name }}
          </template>
        </v-combobox>
      </v-col></v-row
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      currentProfile: (state) => state.profile.currentProfile,
      //   settings: (state) => state.profile.currentProfile.settings,
      shops: (state) => state.profile.shops,
      currentShop: (state) => state.profile.shop.currentShop,
    }),

    currentProfileSelected: {
      get() {
        return this.currentProfile;
      },
      set(profile) {
        if (profile) {
          this.$router.push({
            name: 'merchant-edit',
            params: { id: profile.id },
          });
        } else {
          this.$router.push('/');
        }
      },
    },
    currentShopSelected: {
      get() {
        return this.currentShop;
      },
      set(item) {
        // console.log('currentShopSelected', item);
        if (item) {
          this.$router.push({
            name: 'shop-edit',
            params: { id: this.currentProfile.id, shopId: item.id },
          });
        } else {
          this.$router.push({
            name: 'merchant-edit',
            params: { id: this.currentProfile.id },
          });
        }
      },
    },
  },

  methods: {
    getText(profile) {
      return profile.name || profile.title;
    },
  },
};
</script>

<style>
</style>
