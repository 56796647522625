<template>
  <div class="home">

<ListMerchant v-if="isBackOffice"/>

    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import { mapState } from 'vuex';

import ListMerchant from '@/components/ListMerchantTable.vue';

export default {
  name: 'Home',
  components: {
    ListMerchant,
  },
  computed: {
    ...mapState('user', ['isBackOffice', 'habilitation']),
  },
  methods: {
    async goToFirstShop() {
      await this.$store.dispatch('profile/loadFirstShop', this.$router);
    },
  },
  mounted() {
    this.$store.dispatch('profile/shop/load', undefined);
    this.$store.dispatch('profile/load', undefined);

    if (this.habilitation) {
      if (this.isBackOffice) {
        this.$store.dispatch('profile/loadList');
      } else {
        this.goToFirstShop();
      }
    }
  },
  watch: {
    isBackOffice() {
      if (!this.isBackOffice) {
        this.goToFirstShop();
      }
    },
  },

};
</script>
