import axios from 'axios';

import api from '../lib/api';

import multiClient from '../lib/multiClient';

const store = {
  namespaced: true,

  state: {
    file: null,
  },
  mutations: {
    setFile(state, p) {
      state.file = p;
    },
  },
  actions: {

    async saveLogo({ state, commit, rootState }) {
      if (state.file) {
        // using redirect, we have some CORS issue
        // AND S3 error 'Only one auth mechanism allowed...'
        // https://github.com/axios/axios/issues/2855
        // Finally, use get signedUrl then put the file
        const responseApi = await api.name('profile_import').get(`admin/uploadLogo/${multiClient.get('title')}_${rootState.profile.currentProfile.id}_${state.file.name}?contentType=${state.file.type}`);
        let filePath;
        if (responseApi.data.signedUrl) {
          // we have a signedUrl only if the file does not exist
          await axios.put(
            responseApi.data.signedUrl,
            state.file,
            {
              // onUploadProgress: (progressEvent) =>
              // console.log('file progress', progressEvent.loaded),
              headers: { 'Content-Type': state.file.type },
              maxRedirects: 0,
            },
          );
          const url = new URL(responseApi.data.signedUrl);
          filePath = `${multiClient.get('epmFront')}${url.pathname}`;
        } else {
          filePath = `${multiClient.get('epmFront')}/${responseApi.data.Location}`;
        }
        commit('setFile', null);
        return filePath;
      }
      return undefined;
    },

  },
  modules: {
  },
};

export default {
  ...store,
};
