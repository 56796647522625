import Vue from 'vue';

Vue.filter('capitalize', (value) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('uppercase', (value) => {
  if (typeof value !== 'string') return '';
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
});
