function downloadFile(name, data, type) {
  let blob;
  if (data instanceof Object) {
    blob = JSON.stringify(data);
  } else {
    blob = data;
  }
  const fileURL = window.URL.createObjectURL(
    new Blob([blob], {
      type,
    }),
  );
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute(
    'download',
    name,
  );
  document.body.appendChild(fileLink);
  fileLink.click();
}

function downloadCSV(name, items, myHeader) {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  const headerFriendly = myHeader || header;

  const csv = [
    headerFriendly.join(';'), // header row first
    ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(';')),
  ].join('\r\n');
  downloadFile(name, csv, 'text/csv');
}

module.exports = {
  downloadFile, downloadCSV,
};
