<template>
  <div class="text-field">
    <div
      v-if="addtionalInfo || $slots.addtionalInfo"
      :class="[
        disabled ? 'grey--text' : 'blue--text',
        'text-darken-2 font-weight-bold',
      ]"
    >
      {{ addtionalInfo }}
      <slot name="addtionalInfo"></slot>
      <v-tooltip bottom v-if="help">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="mb-3" color="orange" x-small>
            mdi-help-circle
          </v-icon>
        </template>
        {{ help }}
      </v-tooltip>
    </div>

    <div v-if="deltaShops" class="orange--text text-darken-1 mb-2">
      <span :data-test="dataTest+'_txt-showDelta'">
        {{ deltaLabel }}
      </span>
      <v-btn v-if="deltaShops.length > 0"
      @click="showDelta = !showDelta" :data-test="'bt-'+dataTest + '_show' " >
        {{ showDelta ? "masquer" : "afficher" }}</v-btn
      >
    </div>
    <v-text-field
      v-bind="$attrs"
      v-bind:value="value"
      v-on="inputListeners"
      :rules="rulesComputed"
      outlined
      :disabled="disabled"
      :data-test="dataTest"
    />
  </div>
</template>

<script>
export default {
  name: 'text-field',
  inheritAttrs: false,
  props: {
    dataTest: String,
    label: String,
    addtionalInfo: String,
    deltaShops: Array,
    help: String,
    disabled: Boolean,
    value: [Number, String],
    validators: {
      type: String,
      default: '',
    },
    validatorFunctions: {
      type: Array,
    },
  },
  data() {
    return {
      showDelta: false,
    };
  },

  computed: {
    rulesComputed() {
      const r = [];
      if (this.disabled) {
        return r;
      }
      if (this.validators.includes('required')) {
        r.push((value) => !!value || 'Obligatoire.');
      }
      if (this.validators.includes('email')) {
        r.push((value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || 'E-mail invalide.';
        });
      }
      if (this.validators.includes('url')) {
        // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
        r.push((value) => {
          const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
          return !value || pattern.test(value) || 'URL invalide.';
        });
      }
      if (this.validators.includes('identifiant')) {
        r.push((value) => {
          // a character from a-z, A-Z, 0-9, including the _ (underscore)
          const pattern = /^\w+$/;
          return !value || pattern.test(value) || 'Identifiant invalide. Seulement alphanumérique et des underscores _  ';
        });
      }
      if (this.validatorFunctions) {
        r.push(...this.validatorFunctions);
      }

      return r;
    },
    deltaLabel() {
      let l = '';
      let label;
      if (this.deltaShops.length <= 1) {
        label = `${this.deltaShops.length} point de vente a modifié ce champ`;
      } else {
        label = `${this.deltaShops.length} points de vente ont modifié ce champ`;
      }
      if (this.showDelta) {
        l = this.deltaShops.reduce((acc, s) => `${acc + s.name}, `, '');

        return `${label} : ${l}`;
      }
      return `${label}.`;
    },
    inputListeners() {
      // https://fr.vuejs.org/v2/guide/components-custom-events.html#Relier-des-evenements-natifs-aux-composants
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>

<style>
</style>
