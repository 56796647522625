<template>
  <v-card>
    <v-card-title>
      Profils
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        data-test="search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            color="indigo"
            v-bind="attrs"
            v-on="on"
            @click="exportStatistics"
            :dark="statistics.length !== 0"
            :disabled="statistics.length === 0"
          >
            <v-icon dark> mdi-table-arrow-down </v-icon>
          </v-btn>
        </template>
        <span>Exporter les statistiques</span>
      </v-tooltip>

      <BtnFileImport
        tooltip="import"
        accept=".json"
        icon="mdi-file-import"
        @change="importProfile"
        @help="dialog = true"
        :loading="loading.profile_import"
      />

      <v-btn class="mx-2 ml-4" fab dark color="indigo" @click="dialogIdentifier=true">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <create-identifier-dialog v-model="dialogIdentifier"
      title="Nouveau Profil"
      info="Veuillez saisir un identifiant, comme GENERIQUE_<Nom du groupe>:"
       @onSave="create" @onCancel="dialogIdentifier = false"
        />

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      @click:row="clickRow"
      :item-class="rowClass"
      :loading="loading.profile_get"
      data-test="t-profil"
    >
      <template v-slot:[`header.ptv`]="{ header }">
        <div v-html="header.text"></div>
      </template>
      <template v-slot:[`header.ptvTotal`]="{ header }">
        <div v-html="header.text"></div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-list-item>
          <v-list-item-avatar>
            <!-- contain: causes picture not to be cropped -->
            <v-img
              :alt="`${item.name} avatar`"
              :src="item.settings.logo"
              contain
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>

            <v-tooltip bottom v-if="item.enabled === false">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-subtitle color="cyan" v-bind="attrs" v-on="on">
                  <v-icon color="orange">mdi-alert</v-icon>
                  Désactivé - {{ countEmptyFields(item) }} champs à compléter
                </v-list-item-subtitle>
              </template>
              <span>Champs: {{ getEmptyFields(item) }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.ptvTop`]="{ item }">
        <v-chip
          v-for="(shop, i) in getStat(item).ptvTop"
          :key="i"
          dark
          class="ma-1 clickable"
        >
          {{ shop }}
        </v-chip>
        <v-tooltip bottom v-if="getStat(item).ptvTopRemaining">
          <template v-slot:activator="{ on, attrs }">
            <v-chip dark v-bind="attrs" v-on="on"> ... </v-chip>
          </template>
          <span> {{ getStat(item).ptvTopRemaining }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.ptv`]="{ item }">
        {{ getStat(item).ptv }}
      </template>
      <template v-slot:[`item.ptvTotal`]="{ item }">
        {{ getStat(item).ptvTotal }}
      </template>

      <template v-slot:[`item.CA_LastMonth`]="{ item }">
        {{ formatCurrency(getStat(item).CA_LastMonth / 100) }}
      </template>
      <template v-slot:[`item.CA`]="{ item }">
        {{ formatCurrency(getStat(item).CA / 100) }}
      </template>
      <template v-slot:[`item.nbEmail`]="{ item }">
        {{ getStat(item).nbEmail }}
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Aide > Importer des profils
        </v-card-title>
        <v-card-text>
          Il est possible d'exporter les données de l'environnement de
          Développement/Homologation au format JSON. Puis de les importer en
          production.
          <br />
          <span style="color: orange"> Attention: </span>
          Les données de configuration Payline contenues dans le Profil et le logo ne sont pas
          importées.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { downloadCSV } from '../lib/download';
import { currency } from '../filters/currency';
import BtnFileImport from './lib/BtnFileImport.vue';
import pbm from '../filters/pbm';
import CreateIdentifierDialog from './CreateIdentifierDialog.vue';
import multiClient from '../lib/multiClient';

export default {
  components: {
    BtnFileImport,
    CreateIdentifierDialog,
  },
  data() {
    return {
      dialog: false,
      dialogIdentifier: false,
      search: '',
      headers: [
        {
          text: '',
          value: 'name',
        },
        {
          text: 'Nb Point de vente <br> actif /7 jours',
          value: 'ptv',
          align: 'center',
        },
        { text: 'Nb Point de vente <br> total', value: 'ptvTotal', align: 'center' },

        { text: 'CA mois', value: 'CA', align: 'center' },
        { text: 'CA mois précédent', value: 'CA_LastMonth', align: 'center' },
        { text: 'Nb email mois', value: 'nbEmail', align: 'center' },
        {
          text: 'Top 10',
          value: 'ptvTop',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      statistics: (state) => state.profile.statisticsProfile,
      loading: (state) => state.loading,
    }),
    items() {
      return this.profiles.map((p) => ({ ...this.getStat(p), ...p }));
    },

  },
  methods: {
    ...mapActions('profile', ['importProfile', 'loadStatistics', 'get']),
    ...mapActions('notifications', ['add']),

    ...pbm,
    async create(id) {
      if (id) {
        const profile = await this.get(id);
        if (profile) {
          const { name } = profile;
          this.add({
            title: 'Erreur',
            type: 'error',
            message: `Le profil ${id} existe déjà (${name}).`,
          });
        } else {
          const defaultSettings = multiClient.get('defaultProfile');
          const newItem = {
            id,
            enabled: false,
            payline: {
              // eslint-disable-next-line no-template-curly-in-string
              ref_template: "MAIL_<%=request.ref || `${request.seller}_${moment(request.created_at).format('YYYYMMDDHHmm')}` %>_<%= request.id.slice(0,4) %><%= request.$v || '0' %>",
            },
            settings: {
              expirations_presets: [],
              template: 'default',
              primary_color: '#0053A1',
              ...defaultSettings,
            },
          };
          this.profiles.push(newItem);
          this.$router.push({ name: 'merchant-edit', params: { id: newItem.id } });
        }
      }
    },
    exportStatistics() {
      const now = moment().format('YYYY-MM-DD');
      downloadCSV(
        `stats_(${process.env.VUE_APP_ENV})_${now}.csv`,
        this.statistics.map((s) => {
          const {
            // eslint-disable-next-line camelcase
            id, ptv, ptvTotal, shops, nbEmail, CA_LastMonth, CA,
          } = s;
          const shopNames = shops.map((shop) => shop.shop);
          return {
            id,
            nbShopActif: ptv,
            nbShopTotal: ptvTotal,
            CA_mois: CA,
            CA_moisPrecedent: CA_LastMonth,
            nbEmail_mois: nbEmail,
            topShops: shopNames,
          };
        }),
      );
    },
    getStat(item) {
      const stat = this.statistics.filter((s) => s.id === item.id);
      if (stat.length === 0) {
        return {};
      }
      return stat[0];
    },
    editItem(item) {
      this.$router.push({ name: 'merchant-edit', params: { id: item.id } });
    },
    clickRow(item) {
      this.editItem(item);
    },

    formatCurrency(e) {
      return currency(e);
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
