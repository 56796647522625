import api from '../lib/api';

const settingsFieldsWording = ['success_page_title', 'success_page_message',
  'failure_page_title', 'locked_page_message', 'request_mail_link_message',
  'message', 'payment_page_message', 'updated_order_message', 'confirm_mail_message',
  'expired_page_message', 'legal', 'error_page_message', 'failure_page_message',
];

function addDefaultWording(profileSettings, shop) {
// Add default wording from Profile
  const fieldsAdded = [];
  settingsFieldsWording.forEach((e) => {
    if (!shop.settings[e] && profileSettings[e]) {
      // eslint-disable-next-line no-param-reassign
      shop.settings[e] = profileSettings[e];
      fieldsAdded.push(e);
    }
  });
  // console.log('fields added', fieldsAdded);
}

function cloneShop(p) {
// create a copy to cancel
  const currentShop = { ...p };
  currentShop.settings = { ...p.settings };
  currentShop.payline = { ...p.payline };
  if (p.payline.contract_numbers) {
    currentShop.payline.contract_numbers = [...p.payline.contract_numbers];
  }
  return currentShop;
}

const store = {
  namespaced: true,

  state: {
    currentShop: null,
  },
  mutations: {
    setCurrentShop(state, p) {
      if (p) {
        state.currentShop = p;
      } else {
        state.currentShop = undefined;
      }
    },
    updateSettings(state, p) {
      const newProp = { ...state.currentShop };
      newProp.settings = { ...newProp.settings, ...p };
      state.currentShop = newProp;
    },
  },
  actions: {
    async get(_, shopId) {
      const { status, data } = await api.name('getShop').get(`api/merchant/${shopId}`);
      if (status === 200) {
        return data;
      }
      return undefined;
    },
    async load({
      rootState, commit, dispatch,
    }, p) {
      if (p) {
        if (rootState.profile.shops.length === 0) {
          await dispatch('profile/load', p.profileId, { root: true });
        }
        const shop = cloneShop(rootState.profile.shops.filter((s) => s.id === p.shopId)[0]);
        const profileSettings = rootState.profile.currentProfile.settings;
        addDefaultWording(profileSettings, shop);
        commit('setCurrentShop', shop);
      } else {
        commit('setCurrentShop', undefined);
      }
      // console.log(`OK shopId ${shopId} ${JSON.stringify(rootState.profile.shops[0])}`);
    },
    async save({ state, rootState, commit }) {
      // remove the default wording from Profile
      const shop = state.currentShop;
      const profileSettings = rootState.profile.currentProfile.settings;

      const fieldsRemoved = [];
      settingsFieldsWording.forEach((e) => {
        if (shop.settings[e] && shop.settings[e] === profileSettings[e]) {
          delete shop.settings[e];
          fieldsRemoved.push(e);
        }
      });
      // console.log('fields removed', fieldsRemoved);

      const { status, data } = await api.name('shop_save').post('/admin/shop', shop);
      // console.log(status);
      if (status === 200) {
        const shops = rootState.profile.shops.filter((s) => s.id !== data.id);
        shops.push(data);
        commit('profile/setShops', shops, { root: true });

        const shopCloned = cloneShop(data);
        addDefaultWording(profileSettings, shopCloned);
        commit('setCurrentShop', shopCloned);
      }
    },
    async remove({ rootState }, shop) {
      const shopId = shop.id;
      // console.log('remove', shopId);
      const { status } = await api.name('shop_remove').delete(`/admin/shop/${shopId}`);
      if (status === 200) {
        const { shops } = rootState.profile;
        shops.splice(shops.findIndex((s) => s.id === shopId), 1);
      }
    },
    cancel({ state, rootState, commit }) {
      const shopCloned = cloneShop(rootState.profile.shops
        .filter((s) => s.id === state.currentShop.id)[0]);
      const profileSettings = rootState.profile.currentProfile.settings;
      addDefaultWording(profileSettings, shopCloned);
      commit('setCurrentShop', shopCloned);
    },

    clear({ commit }) {
      commit('setCurrentShop', undefined);
    },
    goBack({ state }, router) {
      router.push({ name: 'merchant-list-shops', params: { id: state.currentShop.profile } });
    },
  },
  modules: {
  },
};

export default {
  ...store,
};
