var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-0"},[_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-0"},[_c('v-combobox',{staticClass:"pr-5",attrs:{"items":_vm.profiles,"label":"Profil","hide-selected":"","data-test":"profiles","item-text":_vm.getText},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.currentProfileSelected),callback:function ($$v) {_vm.currentProfileSelected=$$v},expression:"currentProfileSelected"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"disabled":_vm.shops.length === 0,"items":_vm.shops,"label":"Point de vente","hide-selected":"","data-test":"shops","item-text":_vm.getText},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.currentShopSelected),callback:function ($$v) {_vm.currentShopSelected=$$v},expression:"currentShopSelected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }