var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Profils "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","data-test":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","color":"indigo","dark":_vm.statistics.length !== 0,"disabled":_vm.statistics.length === 0},on:{"click":_vm.exportStatistics}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-table-arrow-down ")])],1)]}}])},[_c('span',[_vm._v("Exporter les statistiques")])]),_c('BtnFileImport',{attrs:{"tooltip":"import","accept":".json","icon":"mdi-file-import","loading":_vm.loading.profile_import},on:{"change":_vm.importProfile,"help":function($event){_vm.dialog = true}}}),_c('v-btn',{staticClass:"mx-2 ml-4",attrs:{"fab":"","dark":"","color":"indigo"},on:{"click":function($event){_vm.dialogIdentifier=true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('create-identifier-dialog',{attrs:{"title":"Nouveau Profil","info":"Veuillez saisir un identifiant, comme GENERIQUE_<Nom du groupe>:"},on:{"onSave":_vm.create,"onCancel":function($event){_vm.dialogIdentifier = false}},model:{value:(_vm.dialogIdentifier),callback:function ($$v) {_vm.dialogIdentifier=$$v},expression:"dialogIdentifier"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-class":_vm.rowClass,"loading":_vm.loading.profile_get,"data-test":"t-profil"},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"header.ptv",fn:function(ref){
var header = ref.header;
return [_c('div',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.ptvTotal",fn:function(ref){
var header = ref.header;
return [_c('div',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"alt":((item.name) + " avatar"),"src":item.settings.logo,"contain":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),(item.enabled === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-subtitle',_vm._g(_vm._b({attrs:{"color":"cyan"}},'v-list-item-subtitle',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-alert")]),_vm._v(" Désactivé - "+_vm._s(_vm.countEmptyFields(item))+" champs à compléter ")],1)]}}],null,true)},[_c('span',[_vm._v("Champs: "+_vm._s(_vm.getEmptyFields(item)))])]):_vm._e()],1)],1)]}},{key:"item.ptvTop",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.getStat(item).ptvTop),function(shop,i){return _c('v-chip',{key:i,staticClass:"ma-1 clickable",attrs:{"dark":""}},[_vm._v(" "+_vm._s(shop)+" ")])}),(_vm.getStat(item).ptvTopRemaining)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"dark":""}},'v-chip',attrs,false),on),[_vm._v(" ... ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getStat(item).ptvTopRemaining))])]):_vm._e()]}},{key:"item.ptv",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStat(item).ptv)+" ")]}},{key:"item.ptvTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStat(item).ptvTotal)+" ")]}},{key:"item.CA_LastMonth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.getStat(item).CA_LastMonth / 100))+" ")]}},{key:"item.CA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.getStat(item).CA / 100))+" ")]}},{key:"item.nbEmail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStat(item).nbEmail)+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Aide > Importer des profils ")]),_c('v-card-text',[_vm._v(" Il est possible d'exporter les données de l'environnement de Développement/Homologation au format JSON. Puis de les importer en production. "),_c('br'),_c('span',{staticStyle:{"color":"orange"}},[_vm._v(" Attention: ")]),_vm._v(" Les données de configuration Payline contenues dans le Profil et le logo ne sont pas importées. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fermer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }