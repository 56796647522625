import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    name: 'ChangePwd',
    path: '/pwd',
    component: () => import(/* webpackChunkName: "ChangePwd" */ '@/views/ChangePwd.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/conf',
    name: 'Configuration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Conf.vue'),
  },
  {
    path: '/merchant/:id/',
    props: true,

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Edit-merchant" */ '../views/Profile.vue'),
    meta: { requiresAuth: true, permissions: ['EPM.admin.profile.write'] },
    children: [

      {
        path: 'shop',
        name: 'merchant-list-shops',
        component: () => import(/* webpackChunkName: "ListShopTable" */ '@/components/ListShopTable.vue'),
      },
      {
        path: 'payline',
        name: 'merchant-payline',
        component: () => import(/* webpackChunkName: "ConfPayline" */ '@/components/ConfPayline.vue'),
      },
      {
        props: true,
        path: 'page&email',
        component: () => import(/* webpackChunkName: "ConfPageEmail" */ '@/components/ConfPageEmail.vue'),
      },
      {
        path: '/',
        component: () => import(/* webpackChunkName: "ConfMerchantProfile" */ '@/components/ConfMerchantProfile.vue'),
      },
      {
        path: 'Profil',
        name: 'merchant-edit',
        component: () => import(/* webpackChunkName: "ConfMerchantProfile" */ '@/components/ConfMerchantProfile.vue'),
      },
      {
        path: 'confavancé',
        component: () => import(/* webpackChunkName: "ConfAdvanced" */ '@/components/ConfAdvanced.vue'),
      },
      {
        path: 'statistique',
        component: () => import(/* webpackChunkName: "ConfMerchantProfile" */ '@/components/ConfMerchantProfile.vue'),
      },
    ],
  },
  {
    props: true,
    path: '/merchant/:id/shop/:shopId/',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Edit-shop" */ '../views/Shop.vue'),
    meta: { requiresAuth: true, permissions: ['EPM.admin.shop.write'] },
    children: [

      {
        props: true,
        path: 'page&email',
        name: 'shop-edit-page-email',
        component: () => import(/* webpackChunkName: "ConfPageEmail" */ '@/components/ConfPageEmail.vue'),
      },
      {
        path: '/',
        component: () => import(/* webpackChunkName: "ConfShop" */ '@/components/ConfShop.vue'),
      },
      {
        name: 'shop-edit',
        path: 'shop',
        component: () => import(/* webpackChunkName: "ConfShop" */ '@/components/ConfShop.vue'),
      },
    ],

  },
];

export default routes;
