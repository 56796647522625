<template>
  <v-dialog :value="value" persistent  max-width="500" >
    <v-card>
      <v-card-title>
        <span class="headline ml-2">{{ title }}</span>
      </v-card-title>

      <v-card-text>
          <div>
              {{ info }}
          </div>

          <text-field
      v-model="id"
      addtionalInfo="Identifiant"
      validators="required,identifiant"
      data-test="i-Identifiant"
    />

           </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="blue darken-1"
          text
          @click="close"
          data-test="bt-d-cancel"
        >
          <span> Annuler </span>
        </v-btn>
        <v-btn color="blue darken-1" text @click="save" data-test="bt-d-save">
          <span> Ok </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextField from './lib/TextField.vue';

export default {
  components: { TextField },
  props: {
    value: Boolean,
    title: String,
    info: String,
  },

  data() {
    return {
      id: null,
    };
  },
  methods: {
    close() {
      this.$emit('onCancel');
    },
    save() {
      this.$emit('onSave', this.id);
    },
  },
};
</script>

<style>
</style>
